@import url('https://fonts.googleapis.com/css2?family=Courier+New:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  background: #000; /* Black background */
  color: #fff;
  font-family: 'Courier New', monospace;
  scroll-behavior: smooth;
  position: relative;
}

h1, h2, h3 {
  font-family: 'Courier New', monospace; /* Ensure headers use the typewriter font */
  font-weight: normal; /* Set font weight to normal */
}

.intro {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.navbar {
  position: fixed;
  top: 1rem; /* Adjust the top position to create space for the hamburger */
  left: 1rem; /* Adjust the left position to create space for the hamburger */
  background: transparent; /* Set background to fully transparent */
  z-index: 3;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 4; /* Ensure it's above the nav-links */
}

.bar {
  width: 100%;
  height: 4px;
  background: #fff; /* Change bar color to white */
  transition: all 0.3s;
}

.bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.bar.open:nth-child(2) {
  opacity: 0;
}

.bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: -200px; /* Start off-screen to the left */
  width: 200px;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* Semi-opaque background */
  transition: left 0.3s ease; /* Animate the left property */
  z-index: 2;
}

.nav-links.open {
  left: 0; /* Move into view */
}

.nav-links li {
  margin: 20px 0;
}

.nav-links li a {
  color: #fff; /* Change link color to white */
  font-size: 18px;
  text-decoration: none;
}

.section {
  padding: 4rem 1rem;
  max-width: 800px;
  margin: auto;
  text-align: left;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better readability */
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #0f0;
  padding-bottom: 0.5rem;
}

.section h3 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.section p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.home .subtitle {
  font-size: 1.5rem;
}

.contact-links {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  margin-top: 1rem;
  font-size: 0.9rem; /* Smaller text for contact links */
  gap: 1rem; /* Add space between the links */
}

.contact-links a {
  color: #fff;
  text-decoration: none;
}

.contact-links a:hover {
  text-decoration: underline;
}

.education-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure the items wrap on smaller screens */
  margin-bottom: 1rem;
}

.degree {
  font-size: 1.2rem;
  flex: 1;
}

.institution-year {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.institution, .year {
  font-size: 1.2rem;
}

.project-item, .education-item {
  margin-bottom: 2rem;
}

.project-item ul {
  margin-left: 1.5rem;
  padding-left: 0;
}

.project-item ul li {
  margin-bottom: 0.5rem; /* Reduced spacing between bullet points */
  line-height: 1.5;
}

.github-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #000; /* Change text color to black for better readability */
  background-color: #0f0;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold; /* Make the button text bold */
  transition: background-color 0.3s ease;
}

.github-button:hover {
  background-color: #0b0;
}

.closed-source-label {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #555;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 4px;
  font-weight: bold;
}

a {
  color: #0f0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap; /* Wrap items to fit smaller screens */
}

.skills-list li {
  flex: 1 1 45%; /* Take up 45% of the width, allowing two items per row */
  margin: 0.5rem 0;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid #0f0;
  padding-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .section {
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .education-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .institution-year {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .institution, .year {
    text-align: left;
    flex: 1;
  }
}

@media (max-width: 480px) {
  .degree {
    font-size: 1rem;
  }

  .institution-year {
    flex-direction: column;
    align-items: flex-start;
  }

  .institution, .year {
    font-size: 1rem;
    text-align: left;
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none; /* Prevent canvas from blocking interactions */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

.section:nth-child(2) {
  animation-delay: 0.2s;
}

.section:nth-child(3) {
  animation-delay: 0.4s;
}

.section:nth-child(4) {
  animation-delay: 0.6s;
}

.section:nth-child(5) {
  animation-delay: 0.8s;
}
